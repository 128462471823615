import { Component } from 'react';
import PropTypes from 'prop-types';
import {observer} from 'mobx-react';

import GoogleMapReact from 'google-map-react';

// used to use https://github.com/gaearon/react-pure-render, but that said to use a React addon which has been
// replaced by React.PureComponent

@observer
class GoogleMap extends Component {
    static propTypes = {
        coordinates: PropTypes.shape({
            lat: PropTypes.number.isRequired,
            lng: PropTypes.number.isRequired,
        }),
        markers: PropTypes.array,
    };

    static defaultProps = {
        defaultCenter: {lat: 59.938043, lng: 30.337157},
        zoom: 15,
        bootstrapURLKeys: {
            key: "AIzaSyAhbBaVfGPlxSdyFZKiC93rG8P6s1Yd3ko",
            language: 'en',
            // this is required because Google changed their API and now icons fly in from the corner...I hate this.
            v: 'quarterly',
        },
    };

    constructor(props) {
        super(props);

        this.state = {
            apiLoaded: false,
            center: props.defaultCenter,
        };

        this.onApiLoad = this.onApiLoad.bind(this);
        this.onChildClick = this.onChildClick.bind(this);
    }

    onApiLoad({ map, maps }) {
        this.GoogleMap = map;
        this.GoogleMapsApi = maps;
        this.setState({apiLoaded: true}, this.setBounds);
    }

    setBounds() {
        const { markers } = this.props;

        if (this.state.apiLoaded === false) {
          return;
        }

        const center = {};
        if (markers.length === 1) {
            center.lat = Number.parseFloat(markers[0].props.lat);
            center.lng = Number.parseFloat(markers[0].props.lng);
        } else {
            const bounds = new this.GoogleMapsApi.LatLngBounds(); // need handler in case `google` not yet available
            markers.forEach(marker => {
                bounds.extend(new this.GoogleMapsApi.LatLng(marker.props.lat, marker.props.lng));
            });
            const boundCenter = bounds.getCenter();
            center.lat = boundCenter.lat();
            center.lng = boundCenter.lng();

            this.GoogleMap.bounds = bounds;
            this.GoogleMap.fitBounds(bounds);

            const zoom = this.GoogleMap.getZoom();
            this.GoogleMap.setZoom(Math.min(zoom, this.props.zoom));
        }

        this.setState({ center });
    }

    onChildClick(key, childProps) {
        const blockSelector = ".sb-block[data-id='" + key + "']";
        $(blockSelector).trigger("click");
    }

    render() {
        const { bootstrapURLKeys, defaultCenter, markers, zoom } = this.props;
        const { center } = this.state;

        return (
            <GoogleMapReact
                ref={(r) => this.mapRef = r }
                yesIWantToUseGoogleMapApiInternals
                onGoogleApiLoaded={this.onApiLoad}
                bootstrapURLKeys={bootstrapURLKeys}
                resetBoundsOnResize={true}
                hoverDistance={20}
                center={center}
                defaultCenter={defaultCenter}
                defaultZoom={zoom}
                onChildClick={this.onChildClick}
            >
                {markers}
            </GoogleMapReact>
        );
    }
}

export default GoogleMap
